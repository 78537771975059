@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap');

.menu{
    position: relative;
    z-index: 2;
}

.desktopMenu {
    position: relative;
    display: flex;
    width: 100%;
    height: 7rem;
    /* margin:0 auto; */
    color: white;
}


.mobileButton{
    width: 100%;
}

.sticky{
    position: fixed;
    background: linear-gradient(90deg, rgba(74,76,179,0.95) 0%, rgba(9,9,121,0.804359243697479) 66%, rgba(206,156,156,0.3435749299719888) 100%);
    z-index:999999;
    margin: 0 auto;
    transform: translateY(-50%);
    animation: show-stickybar 0.3s ease forwards
}

@keyframes show-stickybar {
    to{
        transform: translateY(0%);
    }
}

.mobileButton{
    display: none;
    text-decoration: none;
}

.mobileButton button{
    background: none;
    border:none;
}

button svg{
    width: 40px;
    height: 40px;
    color: white;
}

.desktopMenu ul{
    margin: 0 auto;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-decoration: none;
    padding-left: 0;
}

li{
    padding:0 60px 0 0px; 
}

li:after {
    content: '_';
    position: absolute;
    opacity: 0;
    font-size: 1.5rem;  
}

li:hover:after{
    animation: cursorToggle 1s infinite;
} 

a{
    font-size: 1.6rem;
    cursor: pointer;
    text-decoration: none;
    color: white; 
}

@keyframes cursorToggle {
    0%{opacity:0}
    50%{opacity: 1}
    100%{opacity: 0}
}


.language-switcher{
    display: flex;
    flex-direction: column; 
    align-self: flex-start;
}

.language-switcher .language-option{
    display:flex;
    align-items: center;
    gap: 5px
}

.language-switcher .flag-icon{
    width: 30px;
    height: 20px;
}

@media screen and (max-width: 992px){
    
    .desktopMenu{
        display: none;
    }

    .mobileButton{
        display: block;
    }
}
