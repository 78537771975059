.mobileMenu{
    
}

.mobileMenuContent{
    position:absolute;
    z-index: 5;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
}

.fixed{
    position:fixed;
}

.mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    
}

.options{
    position: absolute;
    z-index: 6;
    top:40%;
    left:33%;
    
} 

.options ul{
    padding:0;
    list-style: none;  
}
