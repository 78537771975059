@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap');

*{
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
}

html, body {
  width: 100%;
  margin: 0 auto; 
  padding: 0;
}

.header::before{
    width: 100%;
    height:100%;
    left: 0;
    right: 0;
    position: absolute;
    content: "";
    background: linear-gradient(90deg, rgba(74,76,179,0.95) 0%, rgba(9,9,121,0.804359243697479) 66%, rgba(206,156,156,0.3435749299719888) 100%);
    z-index: 1;
}

.header{
    margin: 0 auto;
    width: 100%; 
    background-image: url("./images/codebanner.jpg");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    position: relative;   
}

body{
    background-color: #EEEEEE;
}

.container{
    
    /* width: 100%; */
    margin: 0 auto; 
}

.text-container{
    width: 80%;
    margin: 0 auto;
}

.skills-title{
    font-weight: 200;
    font-size: 40px;
    text-align: center;
}

@media (max-width:575px){
    .App::before{
       height:400px
    }
}

@media (min-width: 576px){
    .container{
        max-width: 550px;
    }
}

@media (min-width: 768px){
    .container{
        max-width: 720px;
    }
}

@media (min-width: 992px){
    .container{
        max-width: 960px;
    }
} 

 @media (min-width: 1200px){
    .container{
        max-width: 1140px;
    }
}





