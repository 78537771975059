@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

.resume{
    margin: 20px auto;
    width: 100%;
}

.title{
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    font-size: 40px;
    text-align: center;
}

.circle{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 50%;
    width: 62px;
    height: 62px;
    margin: 0 0 0 -31px;
    background: rgba(74,76,179,.95);
    text-align: center;
    line-height: 62px;
    padding-left: 1px;
    border-radius: 62px;
}

.circle svg{
    width: 40px;
    height: 40px;
}

.timeline{
    width: 80%;
    position:relative;
    margin: 0 auto;
    padding-top: 20px;
}

.timeline:before{
    content:"";
    position: absolute;
    top:0%;
    left:50%;
    width: 3px;
    height: 100%;
    background: linear-gradient(40deg,hsla(0,34%,71%,.95),rgba(74,76,179,.95) 90%);
}

.timelineBlock{
    position: relative;
    min-height: 20%;
    width: 100%;
    margin-top:50px;
}

.timelinePoint{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(74,76,179,.95);
    /* top:4.5%;
    left:48.2%; */
    top:2.5%;
    left:48.2%;
    width: 40px;
    height: 40px;
    margin: 0 0 0 0;
    border-radius: 40px;
}

.timelinePoint svg{
    width: 25px;
    height: 25px;
}

.timelineContent{
    position:relative;
    background-color: #fefefe;
    font-family: 'Poppins', sans-serif;
    padding:20px;
    width: 40%;
}

.contentBox{
    position: relative;
}

.timelineContent .date{
    position: absolute;
    /* right: -58%; */
    right: -56%;
    top: -2%;
    font-size: 1.5rem;
    display: inline-block;
    vertical-align: top;
}

.timelineContent .companyName{
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 2rem;
    font-weight: 600;
}

.timelineContent .position{
    width: fit-content;
    padding: 5px;
    margin-top: 0;
    background-color: #efefef;
    font-size: 1.4rem;
    font-weight: 200;
}

.timelineContent .description{
    margin: 0;
    color:#868686;
    font-size: 1.6rem;
    font-weight: 300;
}

.timelineContent:before{
    content: "";
    position: absolute;
    left: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-left: 7px solid #fefefe;
}


.timeline .timelineBlock:nth-child(even) .timelineContent{
    float:right;
}

.timeline .timelineBlock:nth-child(even) .timelineContent:before{
    content: "";
    position: absolute;
    left:auto;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-left: none;
    border-right: 7px solid #fefefe;
}

.timeline .timelineBlock:nth-child(even) .timelineContent .date{
    text-align: right;
    right:auto;
    top:-1%;
    left:-58%;
}

.timeline .timelineBlock:after{
    content:"";
    display:table;
    clear:both;
}

@media screen and (max-width: 992px){
    
    .resume{
        width: 100%;
        margin-top:30px;
    }

    .circle{
        left:40px;
    }

    .timeline{
        padding-top: 40px;
        margin:0;
    }

    .timeline:before{
        left:39px;
    }

    .timelineBlock{
        margin-bottom: 80px;
    }

    .timelineBlock:last-of-type{
        margin-bottom: 0;
    }

    .timelinePoint{
        left:20px;
        top: -51px;
    }

    .timelineContent,
    .timeline .timelineBlock:nth-child(even) .timelineContent{
        width: 75%;
        float:none;
        left:86px;
    }

    .timelineContent .date,
    .timeline .timelineBlock:nth-child(even) .timelineContent .date{
        left:-21px;
        top:-65px;
        right: 0%;
    }

    .timeline .timelineBlock:nth-child(even) .timelineContent .date{
        text-align: left;
    }

    .timelineContent:before{
        left:auto;
        right: 100%;
        height: 0;
        width: 0;
        border: 7px solid transparent;
        border-right: 7px solid #fefefe;
    }


}