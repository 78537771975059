
.title{
    margin-bottom: 19.2px;
}

.skill-group{
    width: 80%;
    margin: 0 auto 30px auto;
    padding: 20px 20px 40px 20px;
    background-color: #ffffff;  
}

.skill-category{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skill-category .category-title{
    font-size: 2rem;
    margin-bottom: 0px;
    font-weight: 600;
    text-align: center;
}

svg{
    width: 80px;
    height: 80px;
    color: #674188;
}

.skill-info{
    display: flex;
    justify-content: space-between;
}

.skill-name, .skill-percentage{
    font-size: 1.4rem;
    margin-bottom: 8px;
}

.skill-progress{
    position:relative;
    width:100%;
    height: 8px;
    background-color:rgba(0, 0, 0, 0.1) ;
}

.skill-progress .percentage{
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    width:0%;
    background: linear-gradient(40deg,hsla(0,34%,71%,.95),rgba(74,76,179,.95) 90%);;
  
}




